import EvaValidator from "./EvaValidator";

class EvaRequireValidator extends EvaValidator {
  constructor(app) {
    super(app, 'eva-require-validator');
  }

  async validateInternal(value, args) {
    if (value == null) {
      return '$t.core.errors.fields.require';
    }
    if (typeof value === 'number' && isNaN(value)) {
      return '$t.core.errors.fields.require';
    }
    if (typeof value === 'string' && !value.trim()) {
      return '$t.core.errors.fields.require';
    }
    if (Array.isArray(value) && value.length === 0) {
      return '$t.core.errors.fields.require';
    }
    if (typeof value === 'object' && JSON.stringify(value) === '{}') {
      return '$t.core.errors.fields.require';
    }
  }
}

export default EvaRequireValidator;
