<template>
  <div class="eva-video__archive-timeline">
    <div class="timeline-list" @click="setCurrentArchiveTime($event)">
      <div class="timeline-list-div" @click="setCurrentArchiveTime($event)"> {{ firstTimePosition }} </div>
      <div class="timeline-list-div" @click="setCurrentArchiveTime($event)"> {{ secondTimePosition }} </div>
      <div class="timeline-list-div" @click="setCurrentArchiveTime($event)"> {{ thirdTimePosition }} </div>
    </div>
    <div class="timeline-marker" :style="`left: ${markerPosition}px;`" v-tooltip="$eva.$tools.tooltipContent(currentTooltip)"></div>

    <canvas id="archive-canvas" class="eva-video__archive-canvas">
    </canvas>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: 'eva-video-timeline',

  props: {
    markerPosition: {
      type: Number
    }
  },

  data() {
    return {
      ranges: [
        { id: 30, name: '30 минут' },
        { id: 60, name: '1 час' },
        { id: 120, name: '2 часа' },
        { id: 180, name: '3 часа' },
        { id: 360, name: '6 часов' },
        { id: 720, name: '12 часов' },
        { id: 1440, name: 'Текущий день' }
      ]
    }
  },

  computed: {
    firstTimePosition() {
      if (this.selected && this.selected.id) return this.selected.name;
    },
    secondTimePosition() {
      if (this.selected && this.selected.id) {
        const add = (this.currentFrame.id / 2) * 60 * 1000;
        const timestamp = moment(this.selected.name, 'HH:mm:ss').valueOf();
        return moment(timestamp + add).format('HH:mm:ss');
      }
    },
    thirdTimePosition() {
      if (this.selected && this.selected.id) {
        const add = this.currentFrame.id * 60 * 1000;
        const timestamp = moment(this.selected.name, 'HH:mm:ss').valueOf();
        return moment(timestamp + add).format('HH:mm:ss');
      }
    },
    currentTooltip() {
      if (this.selected && this.selected.id) {
        const timestamp = moment(this.selected.name, 'HH:mm:ss').valueOf();
        return this.dateFormatted + moment(timestamp + this.markerPosition / this.millisecondCost).format(' HH:mm:ss');
      }
    }
  },

  methods: {
    async setCurrentArchiveTime(event) {
      if (!this.disabled) {
        this.disabled = true;
        const canvas = document.getElementById("archive-canvas");
        const offset = window.innerWidth - canvas.clientWidth;
        const timlineX = event.x - offset;
        this.archiveData = this.frameItems.find(i => timlineX >= i.x && timlineX <= i.xe);
        if (this.archiveData) {
          clearInterval(this.timerId);
          this.timerId = null;
          this.steps = 0;
          this.startPosition = timlineX;
          this.markerPosition = timlineX;
          const part = this.archiveData.timelength / (this.archiveData.xe - this.archiveData.x);
          this.currentArchiveTime = ((timlineX - this.archiveData.x) * part);
          await this.check_and_go(this.archiveData);
        } else {
          this.disabled = false;
        }
      }
    }
  }
}
</script>

<style lang="less">
.eva-video-timeline {
  .timeline-marker {
    width: 2px;
    height: 21px;
    background-color: red;
    position: absolute;
    cursor: pointer;
  }
}
</style>
