import {nextTick} from "vue";
import {isNil} from "lodash";

class EvaVideoWebrtc {

  constructor(app) {
    this.app = app;
  }

  async getCurrent(channel, data) {
    await this.$eva.$http.post(`/api/v1/videohubservice/integrationapi/archive/${channel}`, data);
  }

  async tryConnect() {
    this.mediaStream = new MediaStream();
    const video = this.$refs.video;
    video.srcObject = this.mediaStream;
    const webrtc = new RTCPeerConnection({
      iceServers: this.iceServers,
      sdpSemantics: 'unified-plan'
    });
    this.webrtc = webrtc;

    this.webrtcSendChannel = webrtc.createDataChannel('rtsptowebSendChannel');
    this.webrtcSendChannel.onopen = (event) => {
      console.log(`${this.webrtcSendChannel.label} has opened`);
      nextTick(() => this.startTimer(value));
    }
    this.webrtcSendChannel.onclose = (_event) => {
      console.log(`${this.webrtcSendChannel.label} has closed`);
    }
    this.webrtcSendChannel.onmessage = event => console.log(event.data);

    for (let i = 0; i < 5; i++) {
      try {
        this.disabled = true;
        console.log('try', i + 1);
        const offer = await webrtc.createOffer();
        await webrtc.setLocalDescription(offer);
        const response = await fetch(videoSource, {
          method: 'POST',
          body: new URLSearchParams({data: btoa(webrtc.localDescription.sdp)})
        });
        await webrtc.setRemoteDescription(new RTCSessionDescription({
          type: 'answer',
          sdp: atob(await response.text())
        }));
        this.disabled = false;
        return;
      } catch (error) {
        console.log(error);
      }
    }
  }

  startTimer(value) {
    this.steps = 0;
    const startTimerTime = new Date();
    if (!isNil(this.timerId)) {
      clearInterval(this.timerId);
      this.timerId = null;
    }

    this.timerId = setInterval(() => {
      if (this.playing) {
        const currentTime = new Date();
        const timeout = this.currentArchiveTime? value.timelength - this.currentArchiveTime : value.timelength;
        if ((currentTime - startTimerTime) < timeout) {
          this.steps += 1000;
        } else {
          clearInterval(this.timerId);
          this.timerId = null;
        }
      } else {
        clearInterval(this.timerId);
        this.timerId = null;
      }
    }, 1000);
  }

  clear() {
    if (this.webrtc) {
      this.webrtc.close();
      this.webrtc = null;
      this.mediaStream = null;
      this.webrtcSendChannel.close();
    }
  }
}

export default EvaVideoWebrtc;
