import Models from "./models/Models";

import Vue from "vue";

import EvaPlugin from "@eva/client/plugins/EvaPlugin";

import EvaForm from "./components/EvaForm";

import EvaFieldWrapper from "./components/fields/EvaFieldWrapper";

import EvaFormLayoutMixin from "./mixins/EvaFormLayoutMixin";
import EvaFormBlockLayout from "./components/layouts/EvaFormBlockLayout";
import EvaFormGridLayout from "./components/layouts/EvaFormGridLayout";

import EvaFieldMixin from "./mixins/EvaFieldMixin";

import EvaTextField from "./components/fields/texts/textField";
import EvaTextAreaField from "./components/fields/texts/textareaField";
import EvaJsonareaField from "./components/fields/texts/jsonareaField";
import EvaTextArrayField from "./components/fields/texts/textArrayField";
import EvaNumberField from "./components/fields/texts/numberField";
import EvaFloatField from "./components/fields/texts/floatField";
import EvaLabelField from "./components/fields/texts/labelField";
import EvaFileField from "./components/fields/files/fileField";
import EvaCheckboxField from "./components/fields/checkboxes/checkboxField";
import EvaSelectField from "./components/fields/selects/selectField";
import EvaComboboxField from "./components/fields/selects/comboboxField";
import EvaSelectColorField from "./components/fields/selects/selectColorField";
import EvaCatalogRefField from "./components/fields/selects/catalogRefField";
import EvaDateField from "./components/fields/datetime/dateField";
import EvaCommandField from "./components/fields/actions/EvaCommandField";

import EvaRadioButtonField from "./components/fields/radio/EvaRadioButtonField";
import EvaTreeField from "./components/fields/tree/EvaTreeField";

import EvaFileAttachField from "./components/fields/files/fileAttachField/EvaFileAttachField";
import EvaSelectFileField from "./components/fields/files/EvaSelectFileField";
import EvaImageField from "./components/fields/files/EvaImageField";
import EvaGalleryField from "./components/fields/files/EvaGalleryField";

import EvaTimeField from "./components/fields/datetime/EvaTimeField";
import EvaDatetimeField from "./components/fields/datetime/EvaDatetimeField";
import EvaIntervalDateField from "./components/fields/intervals/EvaIntervalDateField";
import EvaIntervalTimeField from "./components/fields/intervals/EvaIntervalTimeField";
import EvaRangeDateField from "./components/fields/ranges/EvaRangeDateField";
import EvaDayTimeField from "./components/fields/daytime/EvaDayTimeField";

import EvaIconField from "./components/fields/media/EvaIconField";
import EvaTableField from "./components/fields/tables/EvaTableField";
import EvaFormField from "./components/fields/form/EvaFormField";

import EvaBorderField from "./components/fields/decorations/EvaBorderField";

import EvaValidator from "./models/validators/EvaValidator";
import EvaCustomValidator from "./models/validators/EvaCustomValidator";
import EvaEmailValidator from "./models/validators/EvaEmailValidator";
import EvaLoginValidator from "./models/validators/EvaLoginValidator";
import EvaPasswordValidator from "./models/validators/EvaPasswordValidator";
import EvaPhoneValidator from "./models/validators/EvaPhoneValidator";
import EvaRequireValidator from "./models/validators/EvaRequireValidator";
import EvaRegexpValidator from "./models/validators/EvaRegexpValidator";
import EvaSwitchValidator from "./models/validators/EvaSwitchValidator";
import EvaUniqueValidator from "./models/validators/EvaUniqueValidator";
import EvaRangeValidator from "./models/validators/EvaRangeValidator";

class MetadatasEvaPlugin extends EvaPlugin {

  constructor(app) {
    super(app);
    this.validators = [];
    this.fields = [];
  }

  install() {

    this.app.components(
      EvaForm,
      EvaFieldWrapper
    );

    this.registerLayoutComponents(
      EvaFormBlockLayout,
      EvaFormGridLayout
    );

    this.registerFieldComponents(
      EvaTableField,
      EvaFormField,
      EvaTextField,
      EvaTextArrayField,
      EvaTextAreaField,
      EvaJsonareaField,
      EvaLabelField,
      EvaSelectField,
      EvaComboboxField,
      EvaSelectColorField,
      EvaCheckboxField,
      EvaRadioButtonField,
      EvaTreeField,
      EvaFileField,
      EvaFileAttachField,
      EvaSelectFileField,
      EvaImageField,
      EvaGalleryField,
      EvaCatalogRefField,
      EvaDateField,
      EvaTimeField,
      EvaDatetimeField,
      EvaIntervalDateField,
      EvaIntervalTimeField,
      EvaRangeDateField,
      EvaDayTimeField,
      EvaNumberField,
      EvaFloatField,
      EvaIconField,
      EvaCommandField,
      EvaBorderField,
    );

    this.registerFieldValidators(
      EvaCustomValidator,
      EvaEmailValidator,
      EvaLoginValidator,
      EvaPasswordValidator,
      EvaPhoneValidator,
      EvaRequireValidator,
      EvaRegexpValidator,
      EvaSwitchValidator,
      EvaUniqueValidator,
      EvaRangeValidator
    );

    this.registerRegexpFieldValidators(
      { name: 'email', regexp: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$' },
      { name: 'passport', regexp: '^\\d{4}\\s*-?\\s*\\d{6}$' },
      { name: 'ipaddress', regexp: '^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$' },
      { name: 'number', regexp: '^\\d+$' }
    );


    Models(this.app);
  }

  getModelValue(settings, model) {
    if (!settings.name_in_filter && settings.name.indexOf('.') >= 0) {
      return this.app.$tools.getNestedValue(model, settings.name);
    } else {
      return model[settings.name_in_filter || settings.name];
    }
  }

  setModelValue(settings, model, value) {
    if (!settings.name_in_filter && settings.name.indexOf('.') >= 0) {
      this.app.$tools.setNestedValue(
        model,
        settings.name,
        value,
        (o, k, v) => Vue.set(o, k, v)
      );
    } else {
      Vue.set(model, settings.name_in_filter || settings.name, value);
    }
    Vue.set(settings, 'initialized', true);
  }

  registerComponents(mixin, components) {
    components.forEach((component) => {
      if (!component.mixins) {
        component.mixins = [];
      }
      component.mixins.push(mixin);
      this.app.components(component);
    });
  }

  registerFieldComponents(...components) {
    this.fields.push(...components.map((component) => {
      if (!component.mixins) {
        component.mixins = [];
      }
      component.mixins.push(EvaFieldMixin);
      let name = /^eva-([-a-z]+)-field$/ig.exec(component.name)[1];
      let dbType = component.dbType;
      this.app.components(component);
      return {
        name,
        dbType,
        component
      };
    }));
  }

  registerLayoutComponents(...components) {
    this.registerComponents(EvaFormLayoutMixin, components);
  }

  registerFieldValidators(...validators) {
    this.validators.push(...validators.map((validator) => {
      validator = validator instanceof EvaValidator ? validator : new validator(this.app);
      let name = /^eva-([-a-z]+)-validator$/ig.exec(validator.name)[1];
      return {
        name,
        validator
      }
    }));
  }

  registerRegexpFieldValidators(...validators) {
    this.registerFieldValidators(...validators.map(({ name, regexp }) => {
      return EvaRegexpValidator.create(this.app, name, regexp);
    }))
  }

  getFieldComponent({ type, component }) {
    if (component) {
      return component;
    } else {
      let item = this.fields.find(({name}) => name === type);
      return item && item.component;
    }
  }

  getFieldDbType({ type }) {
    let item = this.fields.find(({name}) => name === type);
    return item && item.dbType;
  }

  getFieldValidator({ type }) {
    let item = this.validators.find(({ name }) => name === type);
    return item && item.validator;
  }

  async openMeta(metaSettings, options = {}) {
    let meta;
    if (metaSettings.columns) {
      meta = {
        params: {
          settings: metaSettings
        }
      };
    } else {
      meta = await this.app.$http.getListFirstItem(`/api/v1/metadata?q=meta_name__eq__${metaSettings.name}`);
      this.app.$tools.handleKeys(
        meta.params.locales,
        (messages, locale) => this.app.$locales.register(locale, messages)
      );
    }
    await this.app.$boxes.showForm({
      type: options.type,
      header: options.header,
      settings: {
        ...meta.params.settings,
        ...(options.settings || {}),
        isnew: options.isnew
      },
      model: options.model || {},
      ok: options.ok
    });
  }
}

export default MetadatasEvaPlugin;
