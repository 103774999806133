<template>
  <div class="eva-repeater" :class="mainClasses">

    <eva-layout column fill transparent>
      <eva-repeater-header
        v-if="showHeader"
        :settings="settings"
        :options="options"
        :class="{ 'eva-border-bottom': true }"
        ref="header"
      >

        <template v-slot:subheader>
          <slot name="subheader"/>
        </template>

        <template v-slot:context>
          <slot name="context" v-bind="{ options }"/>
        </template>

        <slot name="quick-filter" v-bind="{ options }"/>

      </eva-repeater-header>

      <div class="eva-repeater__content">
        <slot name="items" v-bind="{ options, items }"/>
      </div>

      <v-progress-linear
        :color="options.state.loading ? 'primary' : 'transparent'"
        height="2"
        indeterminate
        striped
        style="flex-shrink: 0"
      />

      <eva-repeater-footer
        v-if="showFooter"
        :settings="settings"
        :options="options"
      />
    </eva-layout>

    <eva-layout
      v-if="isPanel"
      column
      scroll
      :style="{ width: panelSize }" class="eva-border-left"
      :class="getPanelClass">
        <component v-if="panelComponent" ref="panelComponent" :is="panelComponent" v-bind="panelComponentProps"/>
        <eva-text v-else header :text="panelText" class="eva-repeater__panel-text"/>
    </eva-layout>

  </div>
</template>

<script>
import { reactive, computed } from 'vue';
import sources from '../sources';
import { isEmpty } from 'lodash';

const LOADING_TIMEOUT = 100;
const DEFAULT_PANEL_SIZE = 400;

export default {
  name: 'eva-repeater',

  props: {
    settings: {
      type: Object
    },
    value: {
      type: Object
    },
    valueAlt: {
      type: Object
    },
    filterValues: {
      type: Object,
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },

  data() {
    this.reload = this.$eva.$tools.debounce(this.reloadInternal, 0);
    this.clear = true;

    const isLoadOnScroll = computed(() => this.settings.pagination === 'scroll');

    const state = reactive({
      initialized: false,
      loading: false,
      isRemote: true,
      isLoadOnScroll,
      error: null,
      groupBy: null,
      total: 0,
      count: 0
    });
    const filter = reactive({
      context: '',
      sorting: '',
      q: {},
      limit: 20,
      offset: 0
    });

    const loadNext = () => {
      if (isLoadOnScroll.value) {
        if (state.total) {
          if (filter.offset + filter.limit < state.total) {
            filter.offset += filter.limit;
            this.clear = false;
          }
        } else if (!this.stopLoadNext) {
          filter.offset += filter.limit;
          this.clear = false;
        }
      }
    }

    return {
      offset: 0,
      source: null,
      stopLoadNext: false,
      options: {
        selectedItem: null,
        selectedAltItem: null,
        state,
        filter,
        filterCommands: [],
        listCommands: [],
        itemCommands: [],
        loadNext
      },
      items: [],
      editing: false,
      reloading: false,
      needReload: false,
      panelComponent: null,
      panelComponentProps: null,
    }
  },

  computed: {
    isSelectable() {
      return this.settings.selectable === true || this.isPanel;
    },
    isPanel() {
      return this.settings.type === 'panel';
    },
    panelSize() {
      return `${this.settings.panelSize || DEFAULT_PANEL_SIZE}px`;
    },
    panelText() {
      return this.$eva.$t(this.settings.panelText || `$t.${this.$options.name}.panelText`);
    },
    showHeader() {
      return this.settings.header !== false;
    },
    showFooter() {
      return this.settings.footer !== false;
    },
    mainClasses() {
      let baseClass = `eva-background-${
        Number.isFinite(this.settings.depth) ? this.settings.depth : 2}`;
 
      if (this.noPadding) {
        baseClass += ' eva-repeater--no-padding eva-border-x eva-border-bottom';
      }
      return baseClass;
    },
    getPanelClass() {
      return this.panelComponent
        ? `eva-background-${Number.isFinite(this.settings.depth) ? this.settings.depth : 2}`
        : 'eva-background-0'
    }
  },

  watch: {
    'options.filter': {
      handler() {
        this.stopLoadNext = false;
        if (this.offset === this.options.filter.offset) {
          this.offset = 0;
          this.options.filter.offset = 0;
          this.$nextTick(() => this.reload({
            clear: this.clear
          }));
        } else {
          this.offset = this.options.filter.offset;
          this.reload({
            clear: this.clear
          });
        }
      },
      deep: true
    },
    'settings.commands': {
      handler() {
        const listCommands = [];
        const itemCommands = [];
        if (this.settings.commands) {
          if (this.settings.commands.item) {
            let commands = this.$eva.$tools.mapObjectOrArray(this.settings.commands.item, (command) => {
              if (command) {
                if (!command.prefix) {
                  command.prefix = `${this.settings.prefix}.commands`;
                }
              }
              return this.$eva.$commands.create(command);
            });
            itemCommands.push(...commands);
          }
          if (this.settings.commands.list) {
            let commands = this.$eva.$tools.mapObjectOrArray(this.settings.commands.list, (command) => {
              if (command) {
                if (!command.prefix) {
                  command.prefix = `${this.settings.prefix}.commands`;
                }
                command.handle.options = this.options;
              }
              return this.$eva.$commands.create(command);
            });
            listCommands.push(...commands);
          }
          if (this.settings.commands === true || this.settings.commands.add !== false) {
            listCommands.push(this.createCommand(this.settings.commands.add, {
              name: 'add',
              prefix: `${this.settings.prefix}.commands`,
              handle: (model, event) => this.addItem(event)
            }));
          }
          if (this.settings.commands === true || this.settings.commands.edit !== false || this.settings.commands.view === true) {
            itemCommands.push(this.createCommand(this.settings.commands.edit, {
              name: 'edit',
              prefix: `${this.settings.prefix}.commands`,
              handle: (item) => this.editItem(item),
            }));
          }
          if (this.settings.commands && !!this.settings.commands.copy) {
            itemCommands.push(this.createCommand(this.settings.commands.copy, {
              name: 'copy',
              prefix: `${this.settings.prefix}.commands`,
              handle: (item) => this.copyItem(item),
            }));
          }
          if (this.settings.commands === true || this.settings.commands.remove !== false) {
            itemCommands.push(this.createCommand(this.settings.commands.remove, {
              name: 'remove',
              prefix: `${this.settings.prefix}.commands`,
              handle: (item) => this.removeItem(item)
            }));
          }
        }
        this.options.listCommands = listCommands;
        this.options.itemCommands = itemCommands;
        this.options.addCommand = listCommands.find((c) => c.name === 'add');
        this.options.editCommand = itemCommands.find((c) => c.name === 'edit');
        this.options.copyCommand = itemCommands.find((c) => c.name === 'copy');
        this.options.clickCommand = this.isSelectable ? this.createCommand({
          handle: (item) => {
            this.options.selectedItem = this.options.selectedItem === item ? null : item;
          }
        }) : itemCommands.find((c) => this.options.editCommand !== c && c.click);
        if (!this.options.clickCommand && this.options.editCommand && this.options.editCommand.click) {
          this.options.clickCommand = this.options.editCommand;
        }
      },
      immediate: true,
      deep: true
    },
    'settings.filter': {
      handler() {
        if (!this.settings.filter) {
          return;
        }
        let columns = this.$eva.$tools.mapObjectOrArray(this.settings.filter.columns, (column) => column)
        if (columns && columns.length) {
          this.options.filterCommands = [this.createCommand({
            name: 'filter',
            handle: async (arg, event) => {
              let model = this.$eva.$tools.clone(this.options.filter.q);
              return await this.$eva.$boxes.showForm({
                header: this.$eva.$t('$t.core.tables.commands.filter.header'),
                anchor: 'bottom-left',
                type: 'dropdown',
                activator: event.target,
                model,
                settings: {
                  prefix: this.settings.prefix,
                  layouts: this.settings.filterLayouts,
                  isFilter: true,
                  width: this.settings.filterWidth || '350px',
                  columns
                },
                ok: () => {
                  this.options.filter.q = model;
                  this.$emit('change-filter', this.options.filter);
                }
              })
            }
          })];
        } else {
          this.options.filterCommands = [];
        }
      },
      immediate: true,
      deep: false
    },
    'settings.groupBy': {
      handler(value) {
        let column = null;
        if (value) {
          if (this.settings.columns) {
            column = this.settings.columns[value];
            if (!column && Array.isArray(this.settings.columns)) {
              column = this.settings.columns.find((c) => c.name === value);
            }
          }
        }
        this.options.state.groupBy = column && (column.name_in_sort || value);
      },
      immediate: true
    },
    'settings.url': {
      handler(value) {
        this.options.state.isRemote = !!value;
      },
      immediate: true
    },
    'settings.urlFilter'() {
      this.options.filter.selectedItem = null;
      this.options.filter.context = '';
      this.options.filter.sorting = '';
      this.options.filter.q = {};
      this.options.filter.limit = 20;
      this.options.filter.offset = 0;
      this.reloadInternal({});
    },
    value(value) {
      this.options.selectedItem = value ? this.items.find((i) => i.id === value.id) : null;
    },
    'options.selectedItem'(value) {
      if (this.isPanel) {
        if (value) {
          if (value && value.id) {
            this.editItem(value);
          }
        } else {
          this.panelComponent = null;
          this.panelComponentProps = null;
        }
      }
      this.$emit('input', value);
    },
    settings() {
      this.options.filter.selectedItem = null;
      this.options.filter.context = '';
      this.options.filter.sorting = '';
      this.options.filter.q = {};
      this.options.filter.limit = 20;
      this.options.filter.offset = 0;
      this.reloadInternal({});
    },
    valueAlt(value) {
      this.options.selectedAltItem = value ? this.items.find((i) => i.id === value.id) : null;
    }
  },

  methods: {
    validateSource() {
      if (!this.source) {
        if (this.settings.url) {
          this.source = sources['url']();
        } else if (this.settings.model) {
          this.source = sources['field']();
        }
      }
      return !!this.source;
    },
    async reloadInternal(state) {
      this.clear = true;
      this.options.state.error = null;

      if (this.options.filterCommands && this.options.filterCommands.length) {
        let badge = false;
        if (this.settings.filter && this.options.filter.q) {
          badge = Object
            .keys(this.options.filter.q)
            .map((key) => this.options.filter.q[key])
            .filter((v) => {
              if (v == null) {
                return false;
              }
              if (typeof v === 'string') {
                return v !== '';
              } else if (Array.isArray(v)) {
                return !!v.length;
              } else if (typeof v === 'object') {
                if (JSON.stringify(v) === '{}' || v.badge === false) {
                  return false;
                }
              }
              return true;
            })
            .length > 0;
        }
        this.options.filterCommands[0].badge = badge;
      }

      if (!this.validateSource()) {
        this.options.selectedItem = null;
        return;
      }

      if (this.reloading) {
        this.needReload = true;
        return;
      }

      let timeout = setTimeout(() => this.options.state.loading = true, LOADING_TIMEOUT);
      try {

        this.reloading = true;

        if (this.settings.beforeLoad) {
          this.settings.beforeLoad(this.options)
        }
        let { items, total } = await this.source.loadItems(this.settings, this.options);
        if (!state.cancel) {
          if (items && this.settings.onLoadItem) {
            items.filter((item) => !!item).forEach(this.settings.onLoadItem);
          }
          this.options.state.total = total || 0;
          this.options.state.count = items.length || 0;
          this.stopLoadNext = !items.length;
          if (this.options.state.isLoadOnScroll && !state.clear) {
            for (let i = 0; i < items.length; i++) {
              if (!this.items.find((item) => item.id === items[i].id)) {
                if (this.options.filter.sorting.includes('%-1')) {
                  this.items.unshift(items[i]);
                } else {
                  this.items.push(items[i]);
                }
              }
            }
          } else {
            this.items = items;
          }

          if (this.options.selectedItem && this.items) {
            this.options.selectedItem = this.items.find((i) => i.id === this.options.selectedItem.id);
          } else {
            this.options.selectedItem = null;
          }
        }
      } catch (error) {
        this.$eva.$logs.error(
            this.$options.name,
            'Ошибка при загрузке данных',
            error
        );
        this.options.state.error = error;
        this.items = [];
        this.options.selectedItem = null;
      } finally {
        if (timeout) {
          clearTimeout(timeout);
        }
        this.options.state.loading = false;
        this.options.state.initialized = true;
        this.reloading = false;
      }

      if (this.needReload) {
        this.needReload = false;
        this.$nextTick(() => this.reloadInternal({}));
      }
    },
    async addItem(event) {
      if (!this.validateSource()) {
        return;
      }
      let model = {};
      let defaultModel = this.settings && this.settings.commands && this.settings.commands.defaultModel || {};
      if (defaultModel) {
        this.$eva.$tools.setDeepDefaults(model, defaultModel);
      }
      let types = this.settings && this.settings.commands && this.settings.commands.add && this.settings.commands.add.types;
      if (types) {
        let item = event && await this.$eva.$boxes.selectItem({
          activator: event.target,
          header: `$t.${this.settings.prefix}.commands.add.types.header`,
          settings: types
        });
        if (item) {
          this.$eva.$tools.setNestedValue(model, types.field, item);
          return await this.addItemInternal(model);
        } else {
          return false;
        }
      } else {
        return await this.addItemInternal(model);
      }
    },
    async addItemInternal(model) {
      let parts;
      if (this.settings.location) {
        parts = location.href.split('?');
        history.pushState({}, null, `${parts[0]}?${this.settings.prefix}=new`);
      }
      model = await this.fromDto(model, 'add');

      if (this.isPanel) {
        this.panelComponent = null;
        this.panelComponentProps = null;
        this.$nextTick(() => {
          let { component, componentProps } = this.getBoxComponent(model, 'add');
          this.panelComponent = component;
          this.panelComponentProps = componentProps;
          this.$emit('item-added');
          this.options.selectedItem = model;
        });
        return false;
      } else {
        let hideOk = this.settings && this.settings.commands && this.settings.commands.add && this.settings.commands.add.ok === false;
        let res = await this.$eva.$boxes.show({
          type: this.settings.type,
          header: this.getDialogHeader('add', model),
          width: this.settings.width,
          ...this.getBoxComponent(model, 'add'),
          commands: {
            ok: hideOk ? undefined : async () => {
              try {
                model = await this.toDto(model, 'add');
                await this.source.addItem(this.settings, this.options, model);
                if (!this.settings.removeRepeaterReload) {
                  await this.reloadInternal({});
                }
                this.$emit('item-added', model);
              } catch (e) {
                this.options.addCommand.showError(e);
                throw e;
              }
            }
          },
          customCommands: this.$eva.$tools.mapObjectOrArray(this.options.addCommand.commands, (command) => {
            let result = Object.assign({}, command);
            result.prefix = `${this.settings.prefix}.commands.add`;
            result.handle = () => command.handle(model);
            return result;
          })
        });
        if (this.settings.location) {
          history.pushState({}, null, `${parts[0]}`);
        }
        return res;
      }
    },
    async editItem(item) {
      if (!this.editing) {
        this.editing = true;
        if (!this.validateSource()) {
          return;
        }

        let parts;
        if (this.settings.location) {
          parts = location.href.split('?');
          history.pushState({}, null, `${parts[0]}?${this.settings.prefix}=${item.id}`);
        }

        const index = this.settings.model?.indexOf(item);
        let model = this.settings.commands.view
            ? item
            : await this.source.getItem(this.settings, this.options, item, index);
        let defaultModel = this.settings && this.settings.commands && this.settings.commands.defaultModel || {};
        if (defaultModel) {
          this.$eva.$tools.setDeepDefaults(model, defaultModel);
        }

        model = await this.fromDto(model, 'edit');
        let hideOk = false;
        if (this.settings && this.settings.commands) {
          if (this.settings.commands.view) {
            hideOk = true;
          } else if (this.settings.commands.edit) {
            if (this.settings.commands.edit.ok === false) {
              hideOk = true;
            } else if (typeof this.settings.commands.edit.ok === 'function') {
              hideOk = this.settings.commands.edit.ok(item);
            }
          }
        }
        if (this.isPanel) {
          this.panelComponent = null;
          this.panelComponentProps = null;
          this.$nextTick(() => {
            let { component, componentProps } = this.getBoxComponent(model, 'edit');
            this.panelComponent = component;
            this.panelComponentProps = componentProps;
          });
          this.editing = false;
          return false;
        } else {
          let res = await this.$eva.$boxes.show({
            type: this.settings.type,
            header: this.getDialogHeader('edit', model),
            width: this.settings.width,
            ...this.getBoxComponent(model, 'edit'),
            commands: {
              ok: hideOk ? undefined : (async () => {
                try {
                  if (this.settings.commands.view !== true) {
                    model = await this.toDto(model, 'edit');
                    await this.source.editItem(this.settings, this.options, model, index);
                    await this.reloadInternal({});
                    this.$emit('item-edited', model);
                  }
                } catch (e) {
                  this.options.editCommand.showError(e);
                  throw e;
                }
              })
            },
            customCommands: [
              ...this.$eva.$tools.mapObjectOrArray(this.options.editCommand.commands, (command) => {
                let result = Object.assign({}, command);
                result.prefix = `${this.settings.prefix}.commands.edit`;
                result.handle = () => command.handle(model);
                return result;
              })
            ]
          });

          if (this.settings.location) {
            history.pushState({}, null, `${parts[0]}`);
          }

          this.editing = false;

          return res;
        }
      }
    },
    async copyItem(item) {
      if (!this.editing) {
        this.editing = true;
        if (!this.validateSource()) {
          return;
        }
        const index = this.settings.model?.indexOf(item);
        let model = await this.source.getItem(this.settings, this.options, item, index);
        model.id = '';
        let defaultModel = this.settings && this.settings.commands && this.settings.commands.defaultModel || {};
        if (defaultModel) {
          this.$eva.$tools.setDeepDefaults(model, defaultModel);
        }

        let parts;
        if (this.settings.location) {
          parts = location.href.split('?');
          history.pushState({}, null, `${parts[0]}?${this.settings.prefix}=new`);
        }

        model = await this.fromDto(model, 'copy');
        let res = await this.$eva.$boxes.show({
          type: this.settings.type,
          header: this.getDialogHeader('copy', model),
          width: this.settings.width,
          ...this.getBoxComponent(model, 'add'),
          commands: {
            ok: async () => {
              try {
                model = await this.toDto(model, 'copy');
                await this.source.addItem(this.settings, this.options, model);
                await this.reloadInternal({});
              } catch (e) {
                this.options.copyCommand.showError(e);
                throw e;
              }
            }
          },
          customCommands: this.$eva.$tools.mapObjectOrArray(this.options.copyCommand.commands, (command) => {
            let result = Object.assign({}, command);
            result.prefix = `${this.settings.prefix}.commands.copy`;
            result.handle = () => command.handle(model);
            return result;
          })
        });

        if (this.settings.location) {
          history.pushState({}, null, `${parts[0]}`);
        }

        this.editing = false;

        return res;
      }
    },
    async removeItem(model) {
      if (!this.validateSource()) {
        return;
      }
      const index = this.settings.model?.indexOf(model);
      model = await this.toDto(model, 'remove');
      const needBackPage = this.items.length === 1 && this.options.filter.offset > 0;
      await this.source.removeItem(this.settings, this.options, model, index);
      if (needBackPage) {
        let offset = this.options.filter.offset - this.options.filter.limit;
        if (offset < 0) {
          offset = 0;
        }
        this.options.filter.offset = offset;
      } else {
        await this.reloadInternal({});
      }
      this.$emit('item-removed', model);
      return 'ok';
    },
    getDialogHeader(command, model) {
      let header = this.settings && this.settings.commands && this.settings.commands[command] && this.settings.commands[command].header;
      if (typeof header === 'string') {
        if (header.startsWith('$current')) {
          return this.$eva.$tools.getNestedValue(model, header.substring(9));
        }
      }
      if (Array.isArray(header)) {
        let str = '';
        for (const i of header) {
          if (!i.startsWith('$current')) {
            str += i;
          } else if (i.startsWith('$current')) {
            str += this.$eva.$tools.getNestedValue(model, i.substring(9));
          }
        }
        return str;
      }
      if (!header) {
        header = `$t.${this.settings.prefix}.commands.${command}.header`;
      }
      return header;
    },
    getBoxComponent(model, mode) {
      let result = {};
      if (this.settings && this.settings.commands) {
        if (this.settings.commands.component) {
          let props = null;
          if (this.settings.commands.componentProps) {
            props = this.settings.commands.componentProps(model);
          }
          result.component = this.settings.commands.component;
          result.componentProps = {
            model,
            settings: this.settings,
            mode,
            repeater: this,
            ...(props || {})
          };
        } else {
          let columns = null;
          switch (mode) {
            case 'add':
              columns = this.$eva.$tools
                .mapObjectOrArray(this.settings.columns, (c) => c)
                .filter((c) => c.showInAdd !== false);
              break;
            case 'edit':
              columns = this.$eva.$tools
                .mapObjectOrArray(this.settings.columns, (c) => c)
                .filter((c) => c.showInEdit !== false);
              break;
            default:
              columns = this.settings.columns;
              break;
          }
          result.component = 'eva-form';
          result.componentProps = {
            model,
            settings: {
              prefix: this.settings.prefix,
              readOnly: this.settings.readOnly,
              columns,
              mode,
              layouts: this.settings.layouts
            }
          };
        }
      }
      return result;
    },
    async fromDto(model, command) {
      let fromDto = this.settings.commands && this.settings.commands[command] && this.settings.commands[command].fromDto;
      return fromDto && await fromDto(model) || model;
    },
    async toDto(model, command) {
      let toDto = this.settings.commands && this.settings.commands[command] && this.settings.commands[command].toDto;
      return toDto && await toDto(model) || model;
    },

    createCommand(settings, defaultSettings) {
      if (typeof settings === 'object') {
        defaultSettings = Object.assign(defaultSettings || {}, settings);
      }
      return this.$eva.$commands.create(defaultSettings);
    }
  },

  mounted() {
    if (this.settings.location) {
      let parts = location.href.split(`${this.settings.prefix}`);
      if (parts.length > 1) {
        this.$nextTick(() => {
          let id = parts[1].substring(1);
          if (id === 'new') {
            if (this.options.addCommand) {
              this.options.addCommand.execute();
            }
          } else {
            if (this.options.editCommand) {
              this.options.editCommand.execute({ id });
            }
          }
        });
      }
    }
    if (this.filterValues !== undefined) {
      this.options.filter = this.filterValues;
    }
  }
}
</script>

<style lang="less">
.eva-repeater {
  height: 100%;
  display: flex;
  flex-direction: row;
  /*gap: @eva-padding;*/
  .eva-repeater__panel-text {
    max-width: 250px;
    align-self: center;
  }
  .eva-repeater__content {
    overflow: auto;
    flex-grow: 1;
    margin-bottom: @eva-padding;
    .v-progress-circular {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: auto;
      transform: translateY(-50%);
      z-index: 100;
    }
  }
  &.eva-repeater--no-padding {
    gap: 0;
    .eva-repeater__content {
      margin-bottom: 0;
    }
  }
}
</style>

<locale lang="ru">
{
  panelText: 'Чтобы увидеть подробности, выберите запись из таблицы'
}
</locale>
