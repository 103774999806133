import EvaHlsVideoSource from "./EvaHlsVideoSource";

class EvaHlsllVideoSource extends EvaHlsVideoSource {
  constructor(source, paused) {
    super(source, paused);
  }

  getSourceUrl(url) {
    return url.replace('webrtc', 'hlsll/live/index.m3u8');
  }
}

export default EvaHlsllVideoSource;
