<template>
  <div :class="$options.name">
    <div ref="container" class="eva-video-player__container">
      <div class="eva-video-player__header eva-foreground-1">
        <slot name="header" />
      </div>

      <video
        v-show="!source?.state?.isError"
        ref="video"
        playsinline
        muted
        :style="`object-fit: ${source && source.state.fit}`"
      />

      <div class="eva-video-player__panel">
        <div>
          <eva-btn
            v-if="!source?.state?.isFullscreen"
            icon="mdi-video"
            type="icon--secondary--small"
            @click="openOnline"
            tooltip="Онлайн"
          />
        </div>
        <eva-spacer />
        <div>
          <eva-btn
            v-if="source && source.supports.screenshot && !source.state.isError"
            icon="mdi-camera"
            type="icon--secondary--small"
            tooltip="Сделать снимок"
            @click="getScreenshot"
          />
          <eva-btn
            v-if="source && source.supports.fit && !source.state.isError"
            class="ml-2"
            :icon="fitIcon"
            type="icon--secondary--small"
            :tooltip="fitTooltip"
            @click="toggleFit"
          />
          <eva-btn
            v-if="source && source.supports.fullscreen"
            class="ml-2"
            :icon="fullscreenIcon"
            type="icon--secondary--small"
            :tooltip="fullscreenTooltip"
            @click="toggleFullscreen"
          />
        </div>
      </div>

      <div v-if="!source || (source && source.state.isError)" class="eva-video__error">
        <eva-icon icon="mdi-video-box-off" />
        <span>Видео не доступно или отсутствует</span>
      </div>
      <eva-progress v-if="source && source.state.isLoading" />
    </div>
  </div>
</template>

<script>
export default {
  name: "eva-video-player",

  props: {
    source: {
      type: Object,
    },
    parent: {
      type: HTMLDivElement,
    },
    box: {
      type: Object,
    },
  },

  data() {
    return {
      disabled: true,
    };
  },

  computed: {
    fullscreenIcon() {
      return this.source.state.isFullscreen
        ? "mdi-fullscreen-exit"
        : "mdi-aspect-ratio";
    },
    fullscreenTooltip() {
      return this.source.state.isFullscreen
        ? "Выход из полноэкранного режима"
        : "Во весь экран";
    },
    fitIcon() {
      switch (this.source.state.fit) {
        case "contain":
          return "mdi-arrow-expand";
        case "cover":
          return "mdi-arrow-expand-all";
        case "fill":
          return "mdi-arrow-collapse";
      }
    },
    fitTooltip() {
      switch (this.source.state.fit) {
        case "contain":
          return "Подстроить";
        case "cover":
          return "Растянуть";
        case "fill":
          return "Заполнить";
      }
    },
  },

  watch: {
    source: {
      handler(value, oldValue) {
        if (value) {
          value.use({
            app: this.$eva,
            container: this.parent,
            parent: this.parent.parentElement,
            video: this.$refs.video,
          });
          value.play();
        } else if (oldValue) {
          oldValue.clear();
        }
      },
      immediate: true,
    },
  },

  methods: {
    openOnline() {
      this.box.hide();
    },
    toggleFullscreen() {
      if (this.source) {
        this.source.toggleFullscreen();
      }
    },
    toggleFit() {
      if (this.source) {
        this.source.toggleFit();
      }
    },
    getScreenshot() {
      if (this.source) {
        this.source.getScreenshot();
      }
    },
  },
};
</script>

<style lang="less">
.eva-video-player {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;
  position: relative;
}
.eva-video-player__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  position: relative;
  .eva-video-player__header {
    height: @eva-header;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 @eva-padding;
    background-color: black;
    opacity: 0.3;
    z-index: 1;
  }
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    /*object-fit: fill;*/
  }
  .eva-video-player__panel {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: @eva-padding;
    z-index: 2000;
  }
  .eva-video__error {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .eva-icon {
      font-size: 34px;
    }
  }
}
</style>
