<template>
  <eva-popup-input
    icon="mdi-calendar"
    :transparent="transparent"
    :class="$options.name"
    @click="showPopup"
    @icon-click="showPopup"
  >
    <eva-text bold :text="formattedValue"/>
  </eva-popup-input>
</template>

<script>
import EvaSelectDatePopup from "./EvaSelectDatePopup";
import moment from "moment";

export default {
  name: 'eva-select-date',

  props: {
    value: {
      type: Number,
      default: null
    },
    transparent: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    formattedValue() {
      return this.value ? moment(new Date(this.value)).format('DD.MM.YYYY') : 'XX.XX.XXXX';
    }
  },

  methods: {
    async showPopup() {
      await this.$eva.$boxes.show({
        activator: this.$el,
        type: 'dropdown',
        anchor: 'bottom-left',
        zIndex: 2003,
        component: EvaSelectDatePopup,
        componentProps: {
          value: this.value,
          select: (value) => this.$emit('input', value)
        }
      });
    }
  }
}
</script>

<style lang="less">
.eva-select-date {
  .eva-textbox__inner {
    padding: 8px 16px;
    .eva-textbox__input {
      display: flex;
      align-items: center;
    }
  }
}
</style>
