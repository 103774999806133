import EvaWebrtcVideoSource from "./EvaWebrtcVideoSource";

class EvaWebrtcLifeVideoSource extends EvaWebrtcVideoSource {
  constructor(source, paused) {
    super(source, paused);
  }

  getDefaultSupports() {
    return {
      screenshot: true,
      fullscreen: true,
      fit: true,
      play: true,
      pause: true
    }
  }

  async getChanel() {
    const stream = await this.app.$http.get(`/api/v1/videohubservice/videostream/${this.source}`);
    return await this.app.$http.get(`/api/v1/videohubservice/integrationapi/${stream.ref_channels[0].id}`);
  }
}

export default EvaWebrtcLifeVideoSource;
