import EvaWebrtcVideoSource from "./EvaWebrtcVideoSource";

class EvaWebrtcArchiveVideoSource extends EvaWebrtcVideoSource {
  constructor(source, paused) {
    super(source, paused);
  }

  getDefaultSupports() {
    return {
      screenshot: true,
      fullscreen: true,
      fit: true,
      play: true,
      pause: true
    }
  }

  async getChanel() {
    const { stream_id, begin_time, end_time, timeout } = this.source;
    return await this.app.$http.post(`/api/v1/videohubservice/integrationapi/archive/${stream_id}`, {
      begin_time,
      end_time,
      timeout
    });
  }
}

export default EvaWebrtcArchiveVideoSource;
