import EvaVideoSource from "./EvaVideoSource";

const PLAY_TIMEOUT = 20000;

class EvaWebrtcVideoSource extends EvaVideoSource {
  constructor(source, paused) {
    super(source, paused);
    this.webrtc = null;
  }

  getDefaultSupports() {
    return {
      screenshot: true,
      fullscreen: true,
      fit: true,
      play: true,
      pause: true
    }
  }

  async playInternal() {
    return new Promise(async (resolve, reject) => {
      try {
        const chanel = await this.getChanel();

        this.webrtc = new RTCPeerConnection({
          iceServers: chanel.ice_servers.map((item) => ({
            urls: item,
            username: chanel.ice_username,
            credential: chanel.ice_credential
          })),
          sdpSemantics: 'unified-plan'
        });
        this.webrtc.ontrack = async (e) => {
          if (!e.streams || !e.streams.length) {
            return reject(new Error('No streams'));
          }
          try {
            this.video.srcObject = e.streams[0];
            await this.app.$tools.promiseTimeout(
              this.video.play(),
              PLAY_TIMEOUT,
              'Play video timeout'
            );
            resolve();
          } catch (error) {
            reject(error);
          }
        }

        this.webrtc.onnegotiationneeded = async (e) => {
          try {
            const offer = await e.target.createOffer();
            await e.target.setLocalDescription(offer);

            const response = await fetch(chanel.url, {
              method: 'POST',
              body: new URLSearchParams({
                data: btoa(e.target.localDescription.sdp)
              })
            });
            const text = await response.text();
            if (e.target.signalingState !== 'closed') {
              await e.target.setRemoteDescription(new RTCSessionDescription({
                type: 'answer',
                sdp: atob(text)
              }));
            } else {
              reject('RTCPeerConnection closed');
            }
          } catch (error) {
            reject(error);
          }
        }
        this.webrtc.addTransceiver('video', {
          direction: 'sendrecv'
        });
      } catch (error) {
        reject(error);
      }
    })
  }

  async clearInternal() {
    this.video.srcObject = null;
    if (this.webrtc) {
      this.webrtc.close();
      this.webrtc = null;
    }
  }

  async getChanel() {

  }
}

export default EvaWebrtcVideoSource;
