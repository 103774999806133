<template>
  <div
    v-show="!isHide"
    class="eva-datetime-field" v-if="!loading"
  >
    <v-menu
      ref="dateMenu"
      :close-on-content-click="false"
      offset-y
      nudge-top="30px"
      min-width="0"
      :disabled="readOnly || preview"
      style="z-index: 10000"
    >
      <template v-slot:activator="{ on, attrs }">
        <eva-input
          :value="!!currentDate"
          :label="fieldLabel"
          :placeholder="fieldPlaceholder"
          :error="fieldErrors"
          :title="fieldTitle"
          :readonly="readOnly"
          :depth="depth"
          :icon="preview ? '' : 'mdi-calendar-range'"
          :preview="preview"
          v-bind="attrs"
          v-on="on"
          @icon-click="on.click"
          :clearable="!!modelValue && !readOnly && !preview"
          @clear="clearField"
          @click="$emit('click', $event)"
        >
          <div>
            {{ currentDate }}
          </div>
        </eva-input>
      </template>
      <eva-layout row class="eva-padding">
        <v-date-picker
          :value="formattedCalendarDate"
          :locale="$locale.current"
          :min="formattedMinDate"
          :max="formattedMaxDate"
          :disabled="readOnly"
          :first-day-of-week="1"
          no-title
          @change="selectDate"
        />
        <v-time-picker
          :value="formattedPickerTime"
          :locale="$locale.current"
          :disabled="readOnly"
          format="24hr"
          @click:hour="selectHour"
          @click:minute="selectMinute"
        />
      </eva-layout>
    </v-menu>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { isNil } from 'lodash';
import {
  formatDateTime,
  formatDateTimeNotUtc,
  formatDateToUnix,
  formatTime,
  formatTimeToUnix
} from './formatters';

export default {
  name: 'eva-datetime-field',

  dbType: 'int',

  data() {
    return {
      loading: false,
      currentDate: '',
      hour: 0,
      minute: 0,
      inputKey: uuid(),
      calendarFormat: 'YYYY-MM-DD',
      timePickerFormat: 'HH:mm',
      maxTimeStr: '23:59',
      format: 'DD.MM.YYYY HH:mm',
      mask: '##.##.#### ##:##',
      maskChecker: /^\d\d.\d\d.\d\d\d\d \d\d:\d\d$/
    };
  },

  computed: {
    min() {
      return this.settings?.min;
    },
    max() {
      return this.settings?.max;
    },
    utc() {
      if (this.settings.utc !== undefined) {
        return this.settings.utc;
      }

      return true;
    },
    currentMin() {
      const lowestMin = moment('01-01-1900', 'DD-MM-YYYY').valueOf();
      if (!this.min) {
        return lowestMin;
      }
      return this.min > lowestMin ? this.min : lowestMin;
    },
    formattedCalendarDate() {
      const value = this.formatEnteredDate(this.modelValue);
      if (value) return value;
      const date = this.utc ? moment.utc().valueOf() : moment().valueOf();
      return this.utc ? formatDateTime(date, this.calendarFormat)
        : formatDateTimeNotUtc(date, this.calendarFormat);
    },
    formattedMinDate() {
      const tz = (new Date()).getTimezoneOffset() * 60000 + 1;
      return this.formatEnteredDate(this.currentMin ? (this.currentMin + tz) : this.currentMin, true);
    },
    formattedMaxDate() {
      const tz = (new Date()).getTimezoneOffset() * 60000 + 1;
      return this.formatEnteredDate(this.max ? (this.max + tz) : this.max, true);
    },
    formattedPickerTime() {
      const currentTime = this.getTimeFromDate(this.modelValue);
      if (isNaN(currentTime) || currentTime == null) {
        return null;
      } else {
        return this.formatEnteredTime(currentTime);
      }
    },
    formattedMinTime() {
      if (!this.min && this.min !== 0) return;
      if (this.getDay(this.modelValue) > this.getDay(this.min)) {
        return ;
      }
      const minTime = this.getTimeFromDate(this.min, true);
      return this.formatEnteredTime(minTime, true);
    },
    formattedMaxTime() {
      if (!this.max && this.max !== 0) return;
      const maxTime = this.getTimeFromDate(this.max, true);
      return this.formatEnteredTime(maxTime, true);
    }
  },

  watch: {
    modelValue: {
      handler() {
        this.setValue();
      }
    }
  },

  mounted() {
    this.setValue();
  },
  
  methods: {
    // todo: rename or remove
    formatEnteredDate(newDate, ignoreUtc) {
      if (isNil(newDate)) return;
      let date;
      if (!ignoreUtc && this.utc) {
        date =
          moment.utc(parseInt(newDate)).isValid()
            ? moment.utc(parseInt(newDate), 'x')
            : undefined;
      } else {
        date =
          moment(parseInt(newDate)).isValid()
            ? moment(parseInt(newDate), 'x')
            : undefined;
      }
      if (isNil(date)) return;
      return (!ignoreUtc && this.utc)
        ? formatDateTime(date, this.calendarFormat)
        : formatDateTimeNotUtc(date, this.calendarFormat);
    },
    // todo: rename or remove
    formatEnteredTime (newTime) {
      let time = newTime;
      if (isNil(newTime)) {
        return;
      }
      if (newTime < 0) {
        time = 0;
      }
      if (moment.duration(newTime).days() > 0) {
        time = formatTimeToUnix(this.maxTimeStr, !!this.settings.seconds ? 'seconds' : 'milliseconds');
      }
      return formatTime(time, this.pickerFormat);
    },
    selectDate(date) {
      let newValue = formatDateToUnix(this.utc
        ? moment.utc(date, this.calendarFormat)
        : moment(date, this.calendarFormat));
      if (this.modelValue) {
        const time = this.getTimeFromDate(this.modelValue)
        newValue += time;
      }
      this.modelValue = newValue;
      this.currentDate = this.utc
        ? formatDateTime(newValue, this.format)
        : formatDateTimeNotUtc(newValue, this.format);
      this.inputKey = uuid();
    },
    getTimeFromDate(date, ignoreUtc) {
      const currentValue = (!ignoreUtc && this.utc)
        ? moment.utc(parseInt(date), 'x')
        : moment(parseInt(date), 'x');
      const hours = currentValue.hours();
      const minutes = currentValue.minutes();
      const seconds = currentValue.seconds();
      const ms = currentValue.milliseconds();
      return (hours * 3600 + minutes * 60 + seconds) * 1000 + ms;
    },
    selectTime(time) {
      let newValue = formatTimeToUnix(time);
      if (this.modelValue) {
        const onlyDate = this.utc
          ? moment.utc(this.modelValue).startOf('day').valueOf()
          : moment(this.modelValue).startOf('day').valueOf();
        newValue += onlyDate;
      } else {
        const onlyDate = this.utc
          ? moment.utc().startOf('day').valueOf()
          : moment().startOf('day').valueOf();
        newValue += onlyDate;
      }
      this.modelValue = newValue;
      this.currentDate = this.utc
        ? formatDateTime(newValue, this.format)
        : formatDateTimeNotUtc(newValue, this.format);
      this.inputKey = uuid();
    },
    selectHour(time) {
      this.hour = time;
      this.selectTime(`${this.hour}:${this.minute}`);
    },
    selectMinute(time) {
      this.minute = time;
      this.selectTime(`${this.hour}:${this.minute}`);
    },
    changeDate(date) {
      const isMatchingMask = this.maskChecker.test(date);
      const dateInvalid = this.utc
        ? !moment.utc(date, this.format).isValid()
        : !moment(date, this.format).isValid(); 
      if (isNil(date) || dateInvalid || !isMatchingMask) {
        this.$refs.DatetimeInput.reset();
        this.clearField();
        return;
      }

      const formattedDate = formatDateToUnix(this.utc
        ? moment.utc(date, this.format)
        : moment(date, this.format));
      if (formattedDate < this.currentMin || !isNil(this.max) && formattedDate > this.max) {
        this.$refs.DatetimeInput.reset();
        this.clearField();
        return;
      }

      this.modelValue = formattedDate;
      this.currentDate = this.utc
        ? formatDateTime(formattedDate, this.format)
        : formatDateTimeNotUtc(formattedDate, this.format);
    },
    setValue() {
      const value = this.modelValue;
      const val = this.utc
        ? formatDateTime(value, this.format)
        : formatDateTimeNotUtc(value, this.format);
      this.currentDate = value ? val : null;
      if (isNaN(this.currentDate) && this.currentDate != null) {
        const time = this.currentDate.split(' ');
        this.hour = time[1].split(':')[0];
        this.minute = time[1].split(':')[1];
      }
      // inputKey позволяет гарантированно применить маску к новому значению
      // https://github.com/probil/v-mask/issues/518 и другие
      this.inputKey = uuid();
    },
    clearField() {
      this.modelValue = null;
      this.currentDate = null;
      this.hour = 0;
      this.minute = 0;
      this.loading = true;
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    getDay(date) {
      date = new Date(date);
      let start = new Date(date.getFullYear(), 0, 0);
      let diff = (date - start) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
      let oneDay = 1000 * 60 * 60 * 24;
      return Math.floor(diff / oneDay);
    }
  }
}
</script>

<style lang="less">
.eva-datetime-field {
  position: relative;
  flex: 1;
  .eva-input {
    height: 100%;
    .eva-textbox__input {
      word-break: break-all;
      height: 100%;
    }
  }
}
</style>
