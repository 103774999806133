import Hls from "hls.js";
import EvaVideoSource from "./EvaVideoSource";

class EvaHlsVideoSource extends EvaVideoSource {
  constructor(source, paused) {
    super(source, paused);
    this.hls = null;
  }

  getDefaultSupports() {
    return {
      screenshot: true,
      fullscreen: true,
      fit: true,
      play: true,
      pause: true
    }
  }

  async playInternal(restart) {
    return new Promise(async (resolve, reject) => {
      try {
        if (restart) {
          const chanel = await this.getChanel();
          const hls = new Hls({
            //TODO Включение режима дебага hls протокола (много полезного в там числе битые сегменты)
            debug: false,
            enableWorker: true,
            backBufferLength: 90
          });
          this.hls = hls;
          const state = this.state;
          const app = this.app;
          this.hls.on(Hls.Events.ERROR, function (event, data) {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.MEDIA_ERROR:
                  app.$logs.info('EvaHlsVideoSource', 'fatal media error encountered, try to recover');
                  hls.recoverMediaError();
                  break;
                case Hls.ErrorTypes.NETWORK_ERROR:
                  state.isError = true;
                  state.isLoading = false;
                  app.$logs.error('EvaHlsVideoSource', 'fatal network error encountered', data);
                  hls.destroy();
                  // All retries and media options have been exhausted.
                  // Immediately trying to restart loading could cause loop loading.
                  // Consider modifying loading policies to best fit your asset and network
                  // conditions (manifestLoadPolicy, playlistLoadPolicy, fragLoadPolicy).
                  break;
                default:
                  state.isError = true;
                  state.isLoading = false;
                  app.$logs.error('EvaHlsVideoSource', 'fatal media error encountered');
                  hls.destroy();
                  break;
              }
            }
          });
          const url = this.getSourceUrl(chanel.url);
          this.hls.loadSource(url);
          this.hls.attachMedia(this.video);
          this.video.addEventListener('durationchange', resolve, { once: true });
        } else {
          resolve();
        }
        this.video.play();
      } catch (error) {
        reject(error);
      }
    })
  }

  async clearInternal() {
    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }
  }

  async getChanel() {
    const { stream_id, begin_time, end_time, timeout } = this.source;
    return await this.app.$http.post(`/api/v1/videohubservice/integrationapi/archive/${stream_id}`, {
      begin_time,
      end_time,
      timeout
    });
  }

  getSourceUrl(url) {
    return url.replace('webrtc', 'hls/live/index.m3u8');
  }
}

export default EvaHlsVideoSource;
