<template>
  <div :class="$options.name" :style="{ 'min-height': settings.table.minHeight }">
    <label v-if="fieldLabel" class="eva-textbox__label">
      {{ fieldLabel }}
    </label>

    <eva-table
      :settings="settings.table"
      :class="tableClasses"
      :readOnly="readOnly"
    >
    </eva-table>
  </div>
</template>

<script>
export default {
  name: 'eva-table-field',

  computed: {
    tableClasses() {
      return {
        'eva-table-field--short': (this.settings && this.settings.table && this.settings.table.short === true),
        'eva-table-field--dense': (this.settings && this.settings.table && this.settings.table.dense === true)
      }
    }
  },

  watch: {
    model: {
      handler() {
        if (this.settings.table.filter) {
          let parts = this.settings.table.filter.split('__');
          if (parts[2].startsWith('$current')) {
            parts[2] = this.getDynamicValue(parts[2]);
            this.settings.table.urlFilter = parts.join('__');
          }
        }
        if (this.settings.table.parent) {
          for (const column of this.settings.table.parent) {
            this.settings.table.commands.defaultModel[column] = this.model;
            this.$set(this.settings.table.commands.defaultModel, column, this.model);
          } 
        }
        if (!this.modelValue) {
          this.modelValue = [];
        }
        this.$set(this.settings.table, 'model', this.modelValue);
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="less">
.eva-table-field {
  min-height: 0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  
  .eva-table {
    padding-left: 0;
    padding-right: 0;
    border-radius: 6px;
    > .eva-layout {
      border-radius: 6px;
    }
    &.eva-table-field--dense {
      .eva-repeater-header {
        flex-direction: row;
        .eva-repeater-header__caption {
          margin: auto;
        }
        .eva-text--subheader {
          flex-grow: 1;
        }
        .eva-repeater-header__tools {
          width: auto;
        }
      }
    }
    &.eva-table-field--short {
      .eva-repeater-header {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        .eva-repeater-header__filter {
          display: none;
        }
        .eva-spacer {
          display: none;
        }
        .eva-repeater-header__list {
          width: 100%;
          .eva-btn {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
