<template>
  <div class="eva-video__aside-panel">
    <div class="eva-video__aside-panel-date eva-background-2">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        :disabled="disabled"
        attach
      >
        <template v-slot:activator="{ on, attrs }">
          <eva-input
            :value="!!dateFormatted"
            icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            @icon-click="on.click"
          >
            <div class="eva-textbox__input">
              {{ dateFormatted }}
            </div>
          </eva-input>
        </template>
        <v-date-picker
          :locale="$locale.current"
          :first-day-of-week="1"
          :max="currentDate"
          v-model="date"
          no-title
          @input="menu = false"
          @change="setDate"
        />
      </v-menu>
    </div>
    <v-divider />
    <eva-table
      class="eva-video__aside-panel-list"
      :settings="tableSettings"
      v-model="selected"
      :value-alt="archiveData"
      ref="archiveTable"
    />
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: 'eva-video-event-list',

  data() {
    return {
      selected: null,
      archiveData: null,
      tableSettings: {
        type: 'drawer',
        prefix: this.$options.name,
        caption: false,
        header: false,
        footer: false,
        tableHeader: false,
        selectable: computed(() => !this.disabled),
        columns: {
          name: {
            type: 'text'
          },
        },
        model: computed(() => [].concat.apply([], this.archiveMap)),
        commands: false
      }
    }
  }
}
</script>

<style lang="less">
.eva-video-event-list {
  .eva-video__aside-panel {
    width: 200px;
    display: flex;
    flex-direction: column;

    .eva-video__aside-panel-date {
      padding: 10px;

      .v-text-field__details {
        display: none;
      }
    }

    .eva-video__aside-panel-list {
      max-height: 100%;
      overflow-y: scroll;

      .v-list {
        padding: 0;

        .v-list-item {
          display: flex;
          justify-content: center;
        }
      }
    }

    .eva-repeater {
      padding: 0;
      display: block;

      .eva-repeater__content {
        margin-bottom: 0;

        table {
          padding: 0;
        }

        .eva-default-table-cell {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>
