<template>
  <div class="eva-list">
    <template v-for="(item, index) in items">
      <div v-if="item.divider"
           :key="index"
           class="eva-list__divider eva-border-bottom"
      >
      </div>
      <a v-else
         :key="index"
         class="eva-list__item"
         :class="{ 'eva-list__item--active': item.active }"
         :href="item.to ? `${baseUrl}#${item.to}` : null"
         @click.stop="runCommand(item)"
      >
        <div class="eva-list__item-inner">
          <div class="eva-list__item-icon">
            <eva-icon :icon="item.icon" :size="32"/>
          </div>
          <div class="eva-list__item-header eva-text-clipped">
            <span>{{ $eva.$t(item.name) }}</span>
            <span v-if="item.subname">{{ item.subname }}</span>
          </div>
          <eva-spacer/>
          <div v-if="hasItems(item)" class="eva-list__item-icon">
            <eva-icon :icon="item.open ? 'mdi-chevron-up' : 'mdi-chevron-down'"/>
          </div>
        </div>
        <div v-if="hasItems(item) && item.open" class="eva-list__item-children">
          <template v-for="(child, childIndex) in item.items">
            <div v-if="child.divider"
                 :key="childIndex"
                 class="eva-list__divider"
            >
            </div>
            <a v-else
               :key="childIndex"
               class="eva-list__item"
               :class="{ 'eva-list__item--active': child.active }"
               :href="child.to ? `${baseUrl}#${child.to}` : null"
               @click.stop="runCommand(child)"
            >
              <div class="eva-list__item-inner">
                <div class="eva-list__item-icon">
                  <eva-icon :icon="child.icon"/>
                </div>
                <div class="eva-list__item-header eva-text-clipped">
                  <span>{{ $eva.$t(child.name) }}</span>
                  <span v-if="child.subname">{{ child.subname }}</span>
                </div>
              </div>
            </a>
          </template>
        </div>
      </a>
    </template>
  </div>
</template>

<script>

export default {
  name: 'eva-list',

  props: {
    items: {
      type: Array,
      default: undefined
    },
    nameField: {
      type: String,
      default: 'name'
    }
  },

  watch: {
    '$route.path': {
      handler(value) {
        this.setActive(value);
      },
      immediate: true
    },
    items() {
      this.setActive(this.$route.path);
    }
  },

  computed: {
    baseUrl() {
      return location.pathname;
    }
  },

  methods: {
    runCommand(item) {
      if (item) {
        if (item.command) {
          item.command();
        } else {
          this.$set(item, 'open', !item.open);
        }
      }
    },
    hasItems(item) {
      return !!(item.items && item.items.length);
    },
    setActive(value) {
      if (this.items) {
        for (let i = 0; i < this.items.length; i++) {
          this.$set(this.items[i], 'active', this.items[i].to === value);
          if (this.items[i].items) {
            for (let j = 0; j < this.items[i].items.length; j++) {
              if (this.items[i].items[j].to === value) {
                if (!this.items[i].open) {
                  this.$set(this.items[i], 'open', true);
                }
                this.$set(this.items[i].items[j], 'active', true);
              } else {
                this.$set(this.items[i].items[j], 'active', false);
              }
            }
          }
        }
      }
    }
  },

  mounted() {

  }
}
</script>

<style lang="less">
.eva-drawer--close {
  .eva-list {
    .eva-list__item {
      .eva-list__item-header {
        display: none;
      }
      .eva-list__item-children {
        display: none;
      }
    }
  }
}
.eva-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  a {
    opacity: 1!important;
  }
  .eva-list__item {
    padding: 0 @eva-padding;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit;
    text-decoration: none;
    .eva-list__item-children {
      display: flex;
      flex-direction: column;
      width: 100%;
      .eva-list__item-inner {
        margin-left: (@eva-padding * 3);
      }
    }
    .eva-list__item-inner {
      height: 32px;
      white-space: nowrap;
      /*border-radius: 6px;*/
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      gap: @eva-padding;
      width: 100%;
      &:hover {
        /*background-color: #E5F1FF;*/
      }
    }
    .eva-list__item-icon {

    }
    .eva-list__item-header {

    }
    &.eva-list__item--active {
      color: white;
      .eva-list__item-inner {
        background-color: #0260CF;
      }
    }
  }
  .eva-list__divider {
    flex: 0 0 auto;
    display: flex;
    width: 100%;
    /*height: @eva-border-width;*/
    /*background-color: @eva-border-color;*/
  }
}
</style>
