<template>
  <div class="eva-select-field-popup eva-background-4 eva-padding-2" :style="{ width: this.width + 'px' }">
    <eva-layout v-if="multiple" column fill scroll style="height: 100%" transparent>
      <eva-checkbox
        v-for="(item, index) in items"
        :key="index"
        :label="$eva.$tools.getNestedValue(item, labelField)"
        :value="!!(value && value.find((v) => (v.id === item.id || v === item.id)))"
        @input="selectItem(item)"
      />
    </eva-layout>
    <eva-layout v-else column fill scroll style="height: 100%">
      <div
        v-for="(item, index) in items"
        :key="index"
        type="text--secondary"
        class="eva-btn--item"
        @click="selectItem(item)"
      >
        {{ $eva.$tools.getNestedValue(item, labelField) }}
      </div>
    </eva-layout>
    <eva-layout row v-if="multiple" no-shrink>
      <eva-spacer/>
      <eva-btn
        type="text--small"
        color="info"
        label="OK"
        @click="save"
      />
      <eva-btn
        type="icon--secondary--small"
        icon="mdi-close"
        @click="cancel"
      />
    </eva-layout>
  </div>
</template>

<script>
export default {
  name: 'eva-select-field-popup',

  props: {
    items: {},
    width: {},
    select: {},
    selected: {},
    box: {},
    multiple: {
      type: Boolean,
      default: false
    },
    returnField: {},
    labelField: {}
  },

  data() {
    return {
      value: []
    }
  },

  watch: {
    selected: {
      handler(value) {
        if (value == null) {
          this.value = [];
        } else if (Array.isArray(value)) {
          this.value.push(...value);
        } else {
          this.value.push(value);
        }
      },
      immediate: true
    }
  },

  methods: {
    selectItem(item) {
      if (this.multiple) {
        let v = this.value && this.value.find((v) => v.id === item.id || v === item.id);
        if (v) {
          let index = this.value.indexOf(v);
          this.value.splice(index, 1);
        } else {
          this.value.push(item);
        }
      } else {
        this.select(item);
        this.box.hide();
      }
    },
    save() {
      this.select(this.value);
      this.box.hide();
    },
    cancel() {
      this.box.hide();
    }
  }
}
</script>

<style lang="less">
.eva-select-field-popup {
  display: flex;
  flex-direction: column;
  gap: @eva-padding;
  max-height: 250px;
  .eva-btn--item {
    justify-content: start;
    background-color: transparent;
    border: none;
    padding: 5px 8px;
    cursor: pointer;
    transition-duration: 0.4s;
    &:hover {
      opacity: 0.5;
    }
  }
  .eva-checkbox {
    height: 40px;
  }
}
</style>
