<template>
  <div
    v-show="!isHide"
    class="eva-file-attach-field"
  >
    <div
      class="eva-file-attach-field-files"
    >
      <eva-text
        v-if="fieldLabel"
        header
        :text="fieldLabel"
      />
      <template
        v-for="(file, index) in files"
      >
        <div
          v-if="!checkType(file.type, 'image')"
          class="eva-file-attach-field-files-file"
          :key="file.name"
        >
          <div class="eva-file-attach-field-files-file-name eva-foreground-2">
            <eva-icon
              icon="mdi-file-document" 
            />
            <div>
              {{ file.name }}
            </div>
          </div>
          <div
            v-if="preview"
            class="eva-foreground-2"
          >
            {{ getFileSizeString(file) }}
          </div>
          <eva-icon
            v-else
            class="eva-file-attach-field-files-file-delete-btn"
            icon="mdi-close"
            @click="deleteFile(index)"
          />
        </div>
      </template>
      <div
        class="eva-file-attach-field-files-images"
      >
        <template
          v-for="(file, index) in files"
        >
          <div
            v-if="checkType(file.type, 'image')"
            class="eva-file-attach-field-files-file"
          >
            <img
              :key="file.name"
              :ref="`file-${file.name}`"
              v-tooltip="$eva.$tools.tooltipContent(file.name)"
              @click="showImageDialog(file)"
            />
            <eva-icon
              v-if="!preview"
              class="eva-file-attach-field-files-file-delete-btn"
              icon="mdi-close"
              @click="deleteFile(index)"
            />
          </div>
        </template>
      </div>
    </div>

    <eva-btn
      v-if="!preview"
      class="eva-file-attach-field-btn"
      type="text-icon--secondary"
      :label="$eva.$t(`$t.${$options.name}.attach`)"
      icon="mdi-paperclip"
      @click="attachFiles"
    />

    <input
      class="eva-display-none"
      ref="uploader"
      name="file"
      type="file"
      multiple
      :accept="acceptTypes"
      :size="fileSize"
      @change="upload($event.target.files)"
    >
  </div>
</template>

<script>
import EvaFileAttachFieldPreviewImageDialog from './EvaFileAttachFieldPreviewImageDialog';

export default {
  name: 'eva-file-attach-field',

  components: {
    EvaFileAttachFieldPreviewImageDialog,
  },

  data() {
    return {
      files: [],
      acceptTypes: '*',
      fileLimit: 10,
      fileSize: 10 * 1024 * 1024, // 10 Mb
    };
  },

  mounted() {
    ['acceptTypes', 'fileLimit', 'fileSize'].forEach((key) => {
      if (this.settings[key]) {
        this.$set(this.$data, key, this.settings[key]);
      }
    });
  },
  

  computed: {
    getBtnType() {
      return this.settings && this.settings.button_settings && this.settings.button_settings.type ? this.settings.button_settings.type : 'text';
    },
    getBtnIcon() {
      return this.settings && this.settings.button_settings && this.settings.button_settings.icon ? this.settings.button_settings.icon : 'mdi-paperclip';
    }
  },

  methods: {
    attachFiles() {
      this.$refs.uploader.click();
    },
    checkType(fileType, checkType) {
      return fileType.includes(checkType);
    },
    getFileIcon(fileType) {
      return 'mdi-file-document';
    },
    getFileSizeString(file) {
      let size = Number.parseFloat(file.size / 1024).toFixed(1);
      let sizeType = 'Кб';

      if (size / 1024 > 1) {
        size = Number.parseFloat(size / 1024).toFixed(1);
        sizeType = 'Мб'; 
      }
      return `${size} ${sizeType}`;
    },
    renderFile(file) {
      this.$nextTick(() => {
        if (file.type.includes('image')) {
          let fr = new FileReader();
          console.log(this.$refs);
          const img = this.$refs[`file-${file.name}`][0];
          fr.onload = function () {
            img.src = fr.result; 
          }
          fr.readAsDataURL(file);
        }
      });
    },
    async upload(files) {
      console.log(files);
      if (files.length + this.files.length <= this.fileLimit) {
        for (let i = 0; i < files.length; ++i) {
          const file = files[i];

          if (file.size > this.fileSize) {
            await this.$eva.$boxes.notifyError(`$t.${this.$options.name}.errors.too_large_file`);
          } else {
            this.files.push(file);
            this.renderFile(file);
          }
        }
      } else {
        await this.$eva.$boxes.notifyError(`$t.${this.$options.name}.errors.file_limit`);
      }
    },
    showFile(file) {
      if (file.type === 'application/pdf') {
        window.open(URL.createObjectURL(file), '_blank');
      } else {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    async showImageDialog(file) {
      await this.$eva.$boxes.show({
        type: 'dialog',
        header: file.name,
        component: EvaFileAttachFieldPreviewImageDialog,
        componentProps: { image: file },
      });
    },

    deleteFile(index) {
      this.files.splice(index, 1);
      this.files.forEach((file) => {
        this.renderFile(file);
      });
    },
  }
}
</script>

<style lang="less">
.eva-file-attach-field {
  & &-btn {
    width: 100%;
    margin-top: 12px;
  }

  & &-files {
    display: flex;
    flex-direction: column;

    & .eva-file-attach-field-files-file {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .eva-file-attach-field-files-file-name {
        display: flex;
        align-items: center;
      }
    }
    
    & .eva-file-attach-field-files-images {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      & .eva-file-attach-field-files-file {
        width: 100px;
        height: 76px;
        position: relative;

        & .eva-file-attach-field-files-file-delete-btn {
          position: absolute;
          right: -5px;
          top: 0;
          border-radius: 50%;
          background-color: #495A6F;
        }

        & .eva-file-attach-field-files-file-delete-btn:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>

<locale lang="ru">
  {
    attach: 'Прикрепить файл',
    errors: {
      file_limit: 'Достигнуто максимальное количество прикрепленных файлов',
      too_large_file: 'Слишком большой размер файла (Больше 10Мб)',
    }
  }
</locale>
