<template>
  <div class="" :tabindex="1" :class="{ 'eva-tree-node__item--selected': item.isSelected }">

    <div :id="item.el_id" :class="item.data.selected == true ? 'indexBlock_selected' : 'indexBlock'"
      :draggable="!!state.drag" @dragstart="onDragStart" @dragover="onDragOver" @drop="onDrop">
      <!-- class="indexBlock" -->
      <div class="checkEvent" :tabindex="0" @click="checkItem(item.el_id)" placeholder="Отметить">
        <!-- view selected item icon -->
        <span :class="icoRadioClass"></span>
      </div>

      <div class="blockFolder" @click="onClick(item.data)"><!-- @click="onClick" -->
        <div class="eva-tree-node-r">

          <div v-if="level > 0">
            <div :style="blockWidth(item.data)"><!-- level-{{ level }} --></div><!--  -->
          </div>
          <!-- folder icon -->
          <span v-if="item.icon && !iconOpenChildren(item.data)" class="eva-tree-node__item-arrow">
            <span class="mdi" :class="item.icon" :style="{ color: item.color }"
              @click="item.isOpen = !item.isOpen"></span>
          </span>

          <!-- alertIcon -->
          <span v-if="iconOpenChildren(item.data)">
            <div class="" style="width: 5px;"></div>
          </span>

          <span v-if="item.iconAlert" class="mdi iconsPadding" :class="item.iconAlert" :style="{ color: item.color }">
          </span>

          <!-- text -->
          <eva-data-name :value="returnName(item.name)" :color="item.color" />
          <eva-spacer />
          <div v-if="item.icons" class="eva-tree-node__item-icons">
            <span v-for="icon in item.icons" :key="icon.icon" class="mdi"
              :class="icon.icon + (icon.disabled === true ? ' eva-tree-node__item-icon--disabled' : '')"
              v-tooltip="$eva.$tools.tooltipContent(icon.tooltip)" @click.stop.prevent="onIconClick(icon)"></span>
          </div>
          <!-- right icon -->
          <div v-if="item.rightIcons" class="eva-tree-node__item-icons iconsPadding">
            <span class="mdi" :class="item.rightIcons" v-tooltip="''"></span>
          </div>

        </div>
      </div>
    </div>

    <div v-if="item.nodes" class="eva-tree-node__children">
      <eva-tree-node-registry v-for="(child, index) in item.nodes" :key="index" :item="child" :state="state"
        :level="level + 1" ref="nodes" />
    </div>

    <!--     <eva-intersect v-if="item.hasPagination && !item.isLoading && item.nodes && item.nodes.length"
      @enter="item.loadNext(item.nodes)" /> -->
  </div>
</template>

<script>
import { treeRemoveStore } from '@piniaStores';
import { mapActions, mapState, storeToRefs } from 'pinia';

const storeTreeRemove = treeRemoveStore();


export default {
  name: 'eva-tree-node-registry',

  props: {
    item: {},
    state: {},
    level: Number
  },

  data() {
    return {
      isActive: false,
    }
  },

  mounted() {

  },

  computed: {
    icoRadioClass() {
      return (
        this.isActive === true
          ? 'mdi mdi-radiobox-marked'
          : 'mdi mdi-radiobox-blank'
      )
    },
  },

  methods: {
    ...mapActions('treeRemove', ['setCheckDevice']),

    blockWidth(object) {
      let width = object.iconAlert !== "mdi-hammer-screwdriver" ? `width: ${this.level * 10 + 15}px` : `width: ${this.level}px`
      return width
    },

    checkItem(id) {
      this.isActive = !this.isActive;
      this.item.data.selected = this.isActive;
    },

    onDragStart(e) {
      if (!this.state.drag) {
        return;
      }
      this.$eva.$dragndrops.beginDragMove(e, this.state.drag, {
        id: this.item.id,
        type: this.item.type,
        name: this.item.name
      });
    },
    onDragOver(e) {
      this.$eva.$dragndrops.allowDrop(e, this.state.drop);
    },
    async onDrop(e) {
      let res = this.$eva.$dragndrops.endDrop(e, this.state.drop);
      let evaTreeNode = res['eva-tree-node'];
      if (evaTreeNode) {
        if (
          evaTreeNode.id !== this.item.id &&
          this.state.drop['eva-tree-node'] &&
          await this.state.drop['eva-tree-node'](evaTreeNode, this.item)
        ) {
          this.state.removeNodes(evaTreeNode.id);
          await this.state.reloadNodes(this.item.id);
        }
        return;
      }
      const keys = Object.keys(res);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key === 'eva-tree-node') {
          continue;
        }
        if (this.state.drop[key]) {
          await this.state.drop[key](res[key], this.item);
        }
      }
    },
    onIconClick(icon) {
      if (!icon.handler || icon.disabled === true) {
        return;
      }
      icon.handler(this.item.data);
    },
    async onClick(objType) {
      if (!this.iconOpenChildren(objType)) {
        /* open child */
        if (!await this.state.beforeSelect()) {
          return;
        }
        this.item.isSelected = !this.item.isSelected;
        /*         if (objType.DeviceCard === 'DeviceCard' || objType.DeviceCard === 'MonitoringObject') {
                  await storeTreeRemove.setCheckDevice({ device: objType, from: 'MonitoringObject' });
                } */

      } else {
        // open device 
        await storeTreeRemove.setCheckDevice({ device: objType, from: 'typeDevice' });
        //await storeTreeRemove.setParenFolder({ folder: objType, from: 'typeDevice' });

      }

      if (objType.type === 'MonitoringObject') {
        let url = `/api/v1/toir/toirdevicecard?q=ref_monitoring_object__eq__${objType.id}`;
        const result = await this.$eva.$http.getList(url);
        await storeTreeRemove.setCheckDevice({ device: result.items[0], from: 'objectMonitoring' });
        await storeTreeRemove.clearParenFolder();
        await storeTreeRemove.setParenFolder({ folder: objType, from: 'objectMonitoring' });
      }
      if (objType.type === 'ToirCatalog') {
        await storeTreeRemove.clearParenFolder();
        await storeTreeRemove.setParenFolder({ folder: objType, from: 'ToirCatalog' });
      }

    },

    returnName(objName) {
      return objName
    },

    iconOpenChildren(objType) {
      return objType.type === "DeviceCard" ? true : false
    }

  },


}
</script>

<style lang="less" scoped>
.tcF3 {
  color: #7B8B9D;
}

.tcOrange {
  color: #F39324;
}

.iconsPadding {
  padding-right: 4px;
  margin-left: 15px;
}

.indexBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;

  .checkEvent {
    text-align: center;
    /* flex: 0 0 45px; */
    width: 45px;
    margin: 0px 0px;
    color: #e4e9ef00;
    cursor: pointer;
    font-size: 1.3rem;
  }
}

.indexBlock_selected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;

  outline: 1px solid #26A4FF;
  background-color: #435973;

  .checkEvent {
    text-align: center;
    /* flex: 0 0 45px; */
    width: 45px;
    margin: 0px 0px;
    color: #e4e9ef;
    cursor: pointer;
    font-size: 1.3rem;
  }
}

.blockFolder {
  /* flex: 1 1 20%; */
  width: calc(100% - 45px);
  cursor: pointer;
}

.indexBlock:hover {
  outline: 1px solid #26A4FF;

  .checkEvent {
    width: 45px;
    margin: 0px 0px;
    color: #e4e9ef;
  }
}

.indexBlock::selection {
  background-color: #E4E9EF;
}

.blockMargin {
  margin-left: 10px;
  width: 10px;
  border: 1px black;
  background-color: aqua;
}

.eva-tree-node__item--selected {
  color: white;
  background-color: #2A3746 !important;
}

.eva-tree-node__item--selected>.eva-tree-node__children {
  background-color: #324459 !important;
}



/* .eva-tn-index {
  padding-left: 15px;
}

.eva-tree-node__children {
  margin-left: 5px;
}

.eva-tree-node-r:hover {
  border: solid #26A4FF;
} */

.eva-tree-node-r {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.eva-data-name {
  margin-left: 5px;
}
</style>
