<template>
  <div class="eva-form" :class="formClasses" :style="fromStyles">
    <component
      v-for="(item, itemIndex) in layouts"
      :key="itemIndex"
      :is="item.component"
      v-bind="item.componentProps"
      :design-mode="designMode"
      :selected-name="selectedName"
      @selected-name="$emit('selected-name', $event)"
      @drop-field="$emit('drop-field', $event)"
    />
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: 'eva-form',

  props: {
    settings: {
      type: Object
    },
    model: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    },
    designMode: {
      type: Boolean
    },
    selectedName: {
      type: String
    },
    noFill: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      components: [],
      layouts: []
    }
  },

  computed: {
    formClasses() {
      return {
        'eva-form--loading': this.loading,
        'eva-form--fill': !this.noFill
      }
    },
    fromStyles() {
      return {
        width: this.settings && this.settings.formWidth
      }
    }
  },

  watch: {
    model(value, oldValue) {
      if (value !== oldValue) {
        this.initialize();
      }
    },
    settings(value, oldValue) {
      if (value !== oldValue) {
        this.initialize();
      }
    },
  },

  methods: {
    initialize() {
      if (this.settings) {
        if (!this.settings.columns) {
          this.settings.columns = this.settings.fields;
          delete this.settings.fields;
        }
        this.components = this.getComponents();
        this.layouts = this.getMainLayouts();
      } else {
        this.components = [];
        this.layouts = [];
      }
    },
    getComponents() {
      if (this.model && this.settings) {
        let columns = this.settings.columns;
        if (columns) {
          return this.$eva.$tools.mapObjectOrArray(columns, (settings) => {
            let component = this.$eva.$metadatas.getFieldComponent(settings);
            let validators = this.getFieldValidators(settings);
            if (settings.defaultValue && this.model[settings.name] == null) {
              this.$set(this.model, settings.name, typeof settings.defaultValue === 'object'
                ? settings.defaultValue
                : this.$eva.$tools.getNestedValue(this, settings.defaultValue));
            }
            this.$set(settings, 'initialized', false);
            this.$set(settings, 'customError', false);
            return {
              settings,
              validators,
              component,
              model: this.model
            };
          }).filter((c) => !this.settings.isnew || c.settings.showInAdd !== false);
        }
      }
      return [];
    },
    getMainLayouts() {
      let result = [];
      let fields = [...this.components];
      if (this.settings.layouts) {
        this.getLayouts(result, fields, this.settings.layouts);
      }
      if (fields.length) {
        result.push({
          component: 'eva-form-block-layout',
          componentProps: {
            formSettings: this.settings,
            settings: {
              fields
            }
          }
        });
      }
      return result;
    },
    getLayouts(result, fields, layouts) {
      let keys = Object.keys(layouts);
      for (let i = 0; i < keys.length; i++) {
        let layout = layouts[keys[i]];
        let settings = {
          name: keys[i],
          hover: layout.hover,
          border: layout.border,
          position: layout.position,
          height: layout.height,
          width: layout.width,
          margin: layout.margin,
          w: layout.w,
          h: layout.h,
          fields: [],
          layouts: []
        };
        result.push({
          component: `eva-form-${layout.type}-layout`,
          componentProps: {
            formSettings: this.settings,
            settings
          }
        });
        if (layout.fields) {
          settings.fields.push(...this.$eva.$tools.mapObjectOrArray(layout.fields, (field, name) => {
            if (typeof field === 'string') {
              let parts = field.split(' ');
              field = {
                name,
                x: parseInt(parts[0]),
                y: parseInt(parts[1]),
                w: parts[2] ? parseInt(parts[2]) : 1,
                h: parts[3] ? parseInt(parts[3]) : 1
              }
            }
            let settings = fields.find((f) => f.settings.name === field.name);
            if (!settings) {
              return;
            }
            let index = fields.indexOf(settings);
            fields.splice(index, 1);
            if (field.x != null || field.y != null || field.height != null) {
              this.$set(settings, 'style', {
                gridArea: `${field.y + 1} / ${field.x + 1} / ${field.y + 1 + (field.h || 1)} / ${field.x + 1 + (field.w || 1)}`,
                height: field.height,
                width: field.width
              });
            }
            return settings;
          }));
        }
        if (layout.layouts) {
          this.getLayouts(settings.layouts, fields, layout.layouts);
        }
      }
    },
    getFieldValidators(settings) {
      return this.$eva.$tools.mapObjectOrArray(settings && settings.validators || [], 'type', (item) => {
        let validator = this.$eva.$metadatas.getFieldValidator(item);
        if (validator) {
          return {
            validator,
            ...item
          };
        }
        console.error(`Wrong validator for field '${settings.name}': ${JSON.stringify(item)}`);
      });
    },
    async fieldErrors(validators, settings) {
      let errors = [];
      for (let i = 0; i < validators.length; i++) {
        const item = validators[i];
        let error = null;
        if (settings.type === 'interval-date') {
          let lowName = settings.low && settings.low.name || `${settings.name}Low`;
          let highName = settings.high && settings.high.name || `${settings.name}High`;
          error = (await item.validator.validate(this.model[lowName], item, this.model, settings)) ||
                  (await item.validator.validate(this.model[highName], item, this.model, settings));
        } else {
          error = await item.validator.validate(
              this.$eva.$metadatas.getModelValue(settings, this.model),
              item,
              this.model,
              settings
          );
        }
        if (error) {
          errors.push(error);
        }
        if (settings.error) {
          errors.push(settings.error);
        }
      }
      return errors.join(' ');
    },
    async validate() {
      let errors = [];
      for (let i = 0; i < this.components.length; i++) {
        let { validators, settings } = this.components[i];
        settings.initialized = true;
        let error = settings.customError || null;

        if (!(settings.readOnly && this.$eva.$tools.resolveValue(settings.readOnly, this.model))) {
          error = (await this.fieldErrors(validators, settings) ? settings.name : '');
        }
        
        if (error) {
          errors.push(error);
        }
      }
      return errors.join(' ');
    }
  },

  mounted() {
    this.initialize();
  }
}
</script>

<style lang="less">
.eva-form {
  padding: @eva-padding;
  display: flex;
  flex-direction: column;
  width: 100%;
  &.eva-form--loading {
    opacity: 0.5;
  }
  &.eva-form--fill {
    min-height: 100%;
  }
}
</style>
