import EvaRepeaterSource from "../EvaRepeaterSource";

class EvaRepeaterUrlSource extends EvaRepeaterSource {

  async loadItems(settings, options) {
    if (settings.url) {
      if (settings.urlFilter) {
        if (typeof settings.urlFilter === 'string') {
          let parts = settings.urlFilter.split('__');
          if (parts.length === 3) {
            options.filter.q[parts[0]] = parts[2];
          }
        } else if (typeof settings.urlFilter === 'function') {
          const { fields } = settings.urlFilter();
          for (let field of fields) {
            options.filter.q[field.name] = field.value; 
          }
        } else {
          options.filter.q[settings.urlFilter.field] = settings.urlFilter;
        }
      }

      return await this.app.$http.getList(settings.url, options.filter, options.state.groupBy);
    } else {
      return super.loadItems(settings, options);
    }
  }

  async getItem(settings, options, model) {
    return await this.app.$http.getItem(`${settings.url}/${model.id}`);
  }

  async addItem(settings, options, model) {
    return await this.app.$http.post(settings.url, model);
  }

  async editItem(settings, options, model) {
    return await this.app.$http.put(`${settings.url}/${model.id}`, model);
  }

  async removeItem(settings, options, model) {
    return await this.app.$http.delete(`${settings.url}/${model.id}`);
  }

}

export default EvaRepeaterUrlSource;
