import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import evaApp from "@eva/client/app";

export const useIncidentsStore = defineStore('incidents', () => {
  const selectedIncidentState = ref({
    moId: ref(null),
    mapId: ref(null),
    mapMonitoringObject: ref(null),
    placeMonitoringObject: ref(null),
  });

  const placeAndMapIsSame = computed(() => selectedIncidentState.value?.mapMonitoringObject.ref_mapsource.id === selectedIncidentState.value?.placeMonitoringObject.ref_mapsource.id);

  async function getMO(id) {
    const result = await evaApp.$http.getItem(
      `/api/v1/iagentservice/monitoringobject/${id}`,
    );

    return result;
  }

  async function setMap(item) {
    try {
      if (item && item.id) {
        let monitoringObject = await getMO(item.id);

        setMoId(monitoringObject.id);

        if (monitoringObject.ref_mapsource && monitoringObject.ref_mapsource.id) {
          setMapId(monitoringObject.id);
          setMapMonitoringObject(monitoringObject)
        } else if (
          monitoringObject
          && monitoringObject.mapObjects
          && monitoringObject.mapObjects.length > 0
        ) {
          for (let i = 0; i < monitoringObject.mapObjects.length; ++i) {
            const { items } = await evaApp.$http.getList(
              `/api/v1/iagentservice/monitoringobject?limit=1&q=ref_mapsource__eq__${
                monitoringObject.mapObjects[i].ref_mapsource.id}`
            );

            if (items.length > 0) {
              const moWithMap = items[0];
              setMapId(moWithMap.id);
              setMapMonitoringObject(moWithMap)
              break;
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function setMapId(id) {
    selectedIncidentState.value.mapId = ref(id);
  };
  
  function setMapMonitoringObject(mo) {
    selectedIncidentState.value.mapMonitoringObject = ref(mo);
  }

  function setMoId(id) {
    selectedIncidentState.value.moId = ref(id);
  }

  async function setPlaceMonitoringObject(id) {
    const item = await getMO(id);
    selectedIncidentState.value.placeMonitoringObject = ref(item);
  }

  return {
    selectedIncidentState,
    placeAndMapIsSame,
    setMapId,
    setMap,
    setMoId,
    setPlaceMonitoringObject,
  }
});


